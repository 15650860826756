import {Input} from "@nextui-org/react";

export const InfoInput = ({placeholder, label, data, setData, name, type, required, disabled}) => {

    const onInputChange = e => {
        setData({...data, [name]: e.target.value});
    }

    return(
        <Input
            disabled={disabled}
            required={required}
            name={name}
            labelPlacement={"inside"}
            label={label}
            placeholder={placeholder}
            type={type}
            onChange={onInputChange}
            classNames={{
                inputWrapper: [
                    "border-[#00000071]",
                    "border-[1px]",
                    "!cursor-text",
                    "text-[16px]"
                ],
            }}
            radius={"sm"}/>
    )
}
