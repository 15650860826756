import {useEffect, useState} from "react";
import axios from "axios";
import {BaseURL} from "../constants/Constants";
import {useNavigate, useParams} from "react-router";
import {ActionButton} from "../components/ActionButton";
import {Button, Spinner} from "@nextui-org/react";

export const TicketList = () => {
    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { ticket_code } = useParams();
    const [receivedData, setReceivedData] = useState({});
    const [tickets, setTickets] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        axios.get(`${BaseURL}/events/tickets/list/${ticket_code}/`).then(
            res => {
                setIsLoading(false);
                console.log(res.data);
                setReceivedData(res.data);
                setTickets(res.data.tickets);
            }
        ).catch(
            e => {
                console.log(e.response.data.message);
            }
        )
    }, []);

    if(!isLoading){
        return(
            <div className={"max-w-[400px] m-auto p-[12px] overflow-auto relative"}>
                <div className={"rounded-[6px] border-[#5A5655] border-[1px] bg-[#323232] mb-[40px] p-[8px] z-50"}>
                    <p className={"text-white font-bold text-[18px]"}>Список билетов</p>
                    <p className={"py-[16px] text-white"}>Количество билетов: <span className={"font-bold"}>{receivedData?.info?.count}</span></p>
                    <Button type={"submit"}
                            disabled={false}
                            fullWidth={true}
                            className={"rounded-md bg-[#FCE345]" +
                                " text-[14px] font-bold text-[black]" +
                                " border-[1px] border-[#5E5E5E]" +
                                " flex justify-between text-center w-full"}
                            onClick={() => {history("/scanner")}}><p className={"text-center w-full"}>
                        Вернуться к сканеру
                    </p></Button>
                </div>
                <div className={"w-full flex flex-col gap-[16px]"}>
                    {
                        tickets.map((ticket, index) => (
                            <div key={index} className={`rounded-[6px] border-[1px] bg-[#323232] p-[8px] flex gap-[16px] flex-col ${ticket_code === ticket.code ? "border-[red]" : "border-[#5E5E5E]"}`}>
                                <p className={"text-white text-bold font-bold text-[18px]"}>{ticket.tariff}</p>
                                <p className={"text-white text-bold text-[14px]"}>Номер билета: {ticket.code}</p>
                                {
                                    ticket.is_activated ?
                                        <Button type={"submit"}
                                                disabled={true}
                                                fullWidth={true}
                                                className={"rounded-md bg-[white]" +
                                                    " text-[14px] font-bold text-[black]" +
                                                    " border-[1px]" +
                                                    " flex justify-between text-center w-full"}
                                                onClick={() => {console.log(ticket.code)}}><p className={"text-center w-full"}>
                                            Активирован {ticket.updated_at}
                                        </p></Button> :
                                        <Button type={"submit"}
                                                disabled={false}
                                                fullWidth={true}
                                                className={"rounded-md bg-[#FCE345]" +
                                                    " text-[14px] font-bold text-[black]" +
                                                    " border-[1px]" +
                                                    " flex justify-between text-center w-full"}
                                                onClick={() => {
                                                    const temp_tickets = [...tickets]
                                                    temp_tickets[index].is_activated = true
                                                    setTickets(temp_tickets);
                                                    axios.get(`${BaseURL}/events/tickets/activate/${ticket.code}/`).then(
                                                        res => {

                                                        }
                                                    ).catch(
                                                        e => {
                                                            console.log(e.response.data.message);
                                                        }
                                                    )
                                                }}><p className={"text-center w-full"}>
                                            Активировать
                                        </p></Button>
                                }

                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
    return(
        <div className={"max-w-[400px] m-auto p-[12px]"}>

        </div>
    )
}
