import useRoutes from "./routes";
import AuthContext from "./context/authContext";
import {useContext} from "react";
import {BrowserRouter as Router} from 'react-router-dom';
import "./index.css";

function App() {
    const {auth} = useContext(AuthContext);
    const routes = useRoutes(auth);

    return (
        <div className={"flex relative"}>
            <Router>
                <div className={"w-full h-screen p-[10px] scroll-smooth relative"}>
                    {routes}
                </div>
            </Router>
        </div>
    );
}

export default App;
