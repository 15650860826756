import {useContext, useState} from "react";
import axios from "axios";
import '../index.css';
import {ActionButton} from "../components/ActionButton";
import {InfoInput} from "../components/InfoInput";
import Logo from "../assets/MainLogo.png";
import {BaseURL} from "../constants/Constants";
import AuthContext from "../context/authContext";

export const Login = () => {
    const {setAuth} = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sendData, setSendData] = useState({
        username: "",
        password: ""
    });
    const submitData = e => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(`${BaseURL}/auth/`, {...sendData}).then(
            res => {
                setIsLoading(false);
                setAuth(res.data.token);
                localStorage.setItem("authToken", `${res.data.token}`);
                const timeoutId = setTimeout(() => {
                    window.location.reload();
                }, 500);
                return () => clearTimeout(timeoutId);
            }
        ).catch(
            err => {
                console.log(err)
                if(err){
                    setIsLoading(false);
                    setError(true);
                }
            }
        )
    }

    return (
        <div className={"relative flex justify-center items-center w-full h-full"}>
            <div className={"absolute top-[20px] max-w-[500px] p-[24px]"}>
                <img draggable={false} src={Logo} className={"max-w-[160px]"}/>
            </div>
            <form onSubmit={submitData} className={"flex flex-col bg-[white] p-[10px] min-w-[300px] gap-[16px] rounded-lg border-[#FCE345] border-[3px]"}>
                <h2 className={"text-[18px] font-bold"}>Вход</h2>
                {error && <p className={"text-[red] text-[12px]"}>Неверная почта / пароль</p>}
                <InfoInput
                    disabled={isLoading}
                    required={true}
                    type={"text"}
                    name={"username"}
                    placeholder={"Введите логин кассы (Например: kassa_1)"}
                    label={"Логин"}
                    data={sendData}
                    setData={setSendData}/>
                <InfoInput
                    disabled={isLoading}
                    required={true}
                    type={"password"}
                    name={"password"}
                    placeholder={"Введите пароль"}
                    label={"Пароль"}
                    data={sendData}
                    setData={setSendData}/>
                <ActionButton
                    type={"submit"}
                    name={"Войти"}
                    isLoading={isLoading}
                    buttonAction={null}/>
            </form>
            <div className={"absolute bottom-[20px] max-w-[500px] p-[24px]"}>
                <p className={"text-center text-[10px] text-[white] font-bold mt-[20px]"}>Все права защищены - © ТОО "ASP Holding"</p>
            </div>
        </div>
    )
}
