import { Scanner } from '@yudiel/react-qr-scanner';
import axios from "axios";
import {BaseURL} from "../constants/Constants";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../context/authContext";
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Spinner, Input
} from "@nextui-org/react";
import {useNavigate} from "react-router";
import {InfoInput} from "../components/InfoInput";
import {ActionButton} from "../components/ActionButton";

export const TicketScanner = () => {
    const history = useNavigate();

    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState("");

    const [scannedCode, setScannedCode] = useState("");

    const scanCode = (code) => {
        setIsLoading(true);
        axios.post(`${BaseURL}/events/ticket/check/`, {code: code}).then(
            res => {
                setScannedCode(code);
                setIsLoading(false);
                history(`/tickets/list/${code}`);
            }
        ).catch(
            e => {
                setScannedCode(code);
                setIsLoading(false);
                setError(e.response.data.message);
                onOpen();
            }
        )
    }

    return(
        <div className={"max-w-[400px] m-auto p-[12px]"}>
            {
                isLoading && <div
                    style={{color:"rgba(0,0,0,0.5)"}}
                    className={"absolute w-screen h-screen top-0 left-0 bg-[#00000080] flex justify-center items-center z-50"}>
                    <Spinner color={"white"}/></div>
            }
            <h2 className={"text-white mb-[10px] font-bold"}>Сканер билетов</h2>
            <Scanner onScan={(result) => (scanCode(result[0].rawValue))} />
            <p className={"text-white mt-[10px] text-[12px]"}>Поднесите билет к сканеру</p>
            <div className={"mt-[20px]"}>
                <Input
                    disabled={isLoading}
                    name={"scanned_code"}
                    labelPlacement={"inside"}
                    label={"Номер билета"}
                    placeholder={"Введите номер билета"}
                    type={"text"}
                    onChange={(e) => {setScannedCode(e.target.value)}}
                    classNames={{
                        inputWrapper: [
                            "border-[#00000071]",
                            "border-[1px]",
                            "!cursor-text",
                            "text-[16px]"
                        ],
                    }}
                    radius={"sm"}/>
                <ActionButton
                    type={"action"}
                    name={"Найти"}
                    isLoading={isLoading}
                    buttonAction={() => {scanCode(scannedCode)}}/>
            </div>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} size={"sm"}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Ошибка</ModalHeader>
                            <ModalBody>
                                <p className={"font-bold text-[black]"}>
                                    Номер билета {scannedCode}
                                </p>
                                <p className={"font-bold text-[red]"}>
                                    {error}
                                </p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" fullWidth={true} className={"bg-[#FCE345] font-bold"} onPress={() => {window.location.reload()}}>
                                    Вернуться к сканеру
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}
