import {Button, Spinner} from "@nextui-org/react";

export const ActionButton = ({name, buttonAction, type, isLoading}) => {
    return(
        <Button type={type}
                fullWidth={true}
            className={"rounded-md bg-[#FCE345] mt-[20px] text-[14px] font-bold text-[black] border-[1px] border-[#5E5E5E] flex justify-between"}
            onClick={buttonAction}><Spinner size={"sm"} color={"secondary"}/>{name}<Spinner size={"sm"} color={isLoading ? "primary" : "secondary"}/></Button>
    )
}
