import React from 'react';
import {Navigate, Route, Routes} from 'react-router';
import {TicketScanner} from "./pages/TicketScanner";
import {TicketList} from "./pages/TicketList";
import {Login} from "./pages/Login";

const useRoutes = (isAuthenticated) => {
    if (isAuthenticated){
        return (
            <Routes>
                <Route path="/scanner" element={<TicketScanner/>} />
                <Route path="/tickets/list/:ticket_code" element={<TicketList/>} />
                <Route path="*" element={<Navigate to="/scanner" />}/>
            </Routes>
        )
    }
    return (
        <Routes>
            <Route path="/" element={<Login/>} />
            <Route path="*" element={<Navigate to="/" />}/>
        </Routes>
    )
}

export default useRoutes;
